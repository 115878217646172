import React from 'react'
import styled from 'styled-components'
import { AppSideNavEmailName } from './AppSideNavEmailName'
import { AppStreamlineRest } from './AppStreamlineRest'

const AppStreamlineWithNavWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .StreamlineSectionSection {
    display: flex;
    justify-content: space-between;
    padding: 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 15px;
    }
  }
  .StreamlineSectionNav {
    order: 0;
    @media (max-width: 1090px) {
      order: 1;
      margin: auto;
    }
  }
  .StreamlineSectionSectionContent {
    order: 1;
    @media (max-width: 1090px) {
      order: 0;
    }
  }
`

export const AppStreamlineWithNav = () => {
  return (
    <AppStreamlineWithNavWapper>
      <div className='StreamlineSectionSection'>
        <div className='StreamlineSectionNav'>
          <AppSideNavEmailName />
        </div>
        <div className='StreamlineSectionSectionContent'>
          <AppStreamlineRest />
        </div>
      </div>
    </AppStreamlineWithNavWapper>
  )
}
