import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    ParaPoint: '⟶',
    ParaOne: "It’s no secret Procurement has come a long way from the initial roots as a tactical function to now being recognized as a critically strategic function streamlining the business and delivering bottom-line savings to increase company margins.  That doesn’t mean the value added by Procurement is sustainable, as the function struggles at times to align its goals and activities with the broader stakeholder requirements and support specific CFO needs.",
    ParaTwoLeft: "In the current business environment, the relationship between Finance, Procurement, and other functional groups are more complex and essential than ever, necessitating seamless cross-functional collaboration to run each organization successfully. In other words, functional towers can no longer operate separately, but instead, require realizing an integrated and shared data set and processes to support successful operations across the corporation. Take this article by",
    ParaAnchor: "Michael Wunderbaldinger,",
    ParaTwoRight: "CFO of Tecom Group, which explains, “Procurement and Finance should be like a married couple, where they support one another through the good and bad times.”",
    AnchorShift: "https://tecomgroup.ae/carousel-items/michael-wunderbaldinger-3/",
    ParaThree: "Raindrop’s Enterprise Spend Management platform can be deployed in minutes, so Procurement and Finance can attain almost instant value.  What does this mean to your company?  Harmony between these teams via improved functional insight and output, clarity on supplier spend, payments, and contractual commitments of encumbered funds.  All of this allows for complete transparency during your annual operating plan (AOP) planning cycle.",
    HeadingOne: "Raindrop is the common language aligning Procurement, Finance, and internal stakeholder groups to realize and achieve shared objectives.",
    ParaFour: "Raindrop's Enterprise Spend Management solution is a perfect example of instant value attainment delivered in the cloud as a SaaS platform technology.  Business leaders, including Procurement and Finance, understand the urgency and importance of defining and executing their organization's digital transformation.  The top mutual goals for digitization projects include process automation, reporting data quality, cost savings, and streamlined compliance, collaboration, etc. Raindrop's frictionless and integrated platform architecture was designed from the ground up to provide deeper capabilities through Artificial Intelligence (AI) and Machine learning (ML), offering rapid insights on external spend from planning through the operation.  This enable procurement and finance to reduce the various digital point solution systems footprint while actively streamlining processes and recouping thousands of dollars in operational and capital expenses.",
    HeadingTwo: "Let’s look at the ways on how synergy between Procurement and Finance can be achieved through Raindrop. ",
    HeadingTwoPointOne: "Drive Savings",
    ParaFive: "Raindrop’s frictionless integration and user experience ensure the team has a unified view of spend and can drive specific actions to maximize the value of your investments. Utilizing Raindrop’s Machine Learning and Artificial Intelligence inbuilt technologies, Procurement and Finance can gain immediate spend visibility and advanced spend intelligence, allowing to collaborate and deliver on the cost reduction and supplier spend efficiency strategies. Raindrop’s “Spend Recommendation” technology provides insight into market discounting across a large number of commodities, further enabling efficiencies across your enterprise. Finance and Procurement can avoid out-of-compliance spending and cost overruns by gaining insight into commodity pricing, resulting in market-correct pricing guidance and harnessing spend leakage. Automating a lot of manual tasks and having an up-to-date database with all your suppliers with respect to the spend, commodities purchased, contracts in place, next events, etc., helps provide a complete picture of supplier performance, risks, areas of improvement, etc. All this ties to one key aspect to avoid spend leakage.",
    HeadingTwoPointTwo: "Consolidate Data Silos",
    ParaSix: "One key barrier for procurement and finance to coordinate effectively and efficiently is due to the spend data being fragmented over multiple systems. With this approach, Finance and Procurement are not able to quantify or look at the corporate spend in a holistic manner, resulting in spend leakage.  Raindrop provides Spend Analytics, Spend Planning, Sourcing, Contract Lifecycle Management, and Supplier Relationship Management. Instead of data silos across multiple products, Raindrop’s frictionless integration and user experience ensure procurement and finance get a unified view of spend and drive specific actions to maximize the value of your investments. Raindrop ties all the technologies for effective spend management into an easy-to-use platform that enables you to do your business your way. Raindrop’s unified system capabilities deliver revenue forecasts, financial analysis, simplify annual budget planning, helps identify risks in advance, which are critical components for both procurement and finance.",
    HeadingTwoPointThree: "Spend Analytics to drive business strategies",
    ParaSeven: "When we talk about Spend Analytics, what does it mean? As a broad brushstroke, Spend Analytics is the capture, configuration, understanding, and application of relevant data points as generated from data residing in various source systems, such as ERP systems, eProcurement software, travel and expense systems, P-cards, and other data systems, all which exist to manage complex data sets. What information each user can extract and utilize for their benefit is what is considered “Spend Analytics”. In today’s era of digitization, just getting the data or analytics from spend management solutions is not enough. Ensuring clean and complete information is used as the starting point and managing this between various technology systems (such as ERP, CMS, HR systems, etc.) is critical. After all, good and complete data results in good and comprehensive outcomes.",
    ParaEight: "Raindrop provides in-depth insights on analytics through visualization. Charts, graphs, tables, etc. in whichever format you desire are all immediately accessible with one click. It encapsulates multiple types of key analytics into one combined or separate report like consumption analytics, cost benchmarking analytics, spend analytics, bid analytics, contract analytics, supplier risk, and performance analytics, and many more which provide finance and procurement with the transparency and insights to effectively co-ordinate and deliver on their corporate goals.",
    HeadingTwoPointFour: "Accelerate solution adoption",
    ParaNine: "Developing digital skills is a prerequisite for Finance and Procurement leaders as organizations and their respective functions itself accelerate the adoption of digital technologies. Ease of use is a desired element for any successful technology or business transformation. Simply put, it drives platform adoption. Suppose the system is complex and requires extensive training or setup effort. In that case, it can act as a barrier to technology adoption and compliance, essentially negating the value proposition one sought to achieve in the first place. Raindrop’s powerful, intuitive, flexible, simple to navigate, and easy to adopt solution is designed to address the current system adoption barriers primarily. It provides an intuitive user interface and tools, increasing collaboration between internal teams for better synergy and outcomes.",
  },
]
